import { defineComponent } from 'vue';
import LeistungenTable from '@/components/tables/settings/Leistungen/LeistungenTable.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    LeistungenTable: LeistungenTable
  },
  data: function data() {
    return {};
  },
  computed: {
    nullEins: function nullEins() {
      return editSettingsStore.getters.nullEins;
    },
    prophylaxe: function prophylaxe() {
      return editSettingsStore.getters.prophylaxe;
    },
    implantat: function implantat() {
      return editSettingsStore.getters.implantat;
    },
    pa: function pa() {
      return editSettingsStore.getters.pa;
    },
    availableLeistungen: function availableLeistungen() {
      return editSettingsStore.getters.availableLeistungen;
    },
    availableProphylaxe: function availableProphylaxe() {
      return editSettingsStore.getters.availableProphylaxe;
    },
    availableImplantat: function availableImplantat() {
      return editSettingsStore.getters.availableImplantat;
    },
    availablePa: function availablePa() {
      return editSettingsStore.getters.availablePa;
    }
  }
});