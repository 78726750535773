import { IRoseAGGridColumn } from '@rose/common-ui';
import { ILeistungenSettings, IBemaGozStammdaten } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsLeistungenColumnsDefs(key: keyof ILeistungenSettings): IRoseAGGridColumn<IBemaGozStammdaten>[] {
  return [
    {
      headerName: 'Nummer',
      field: 'nummer',
      sortable: true,
      sort: 'asc',
      width: 120,
    },
    {
      headerName: 'Kürzel',
      field: 'kuerzel',
      width: 80,
      sortable: true,
    },
    {
      headerName: 'Text',
      field: 'text',
      sortable: true,
      resizable: true,
      flex: 2,
    },
    {
      headerName: 'Art',
      field: 'typ',
      width: 80,
      sortable: true,
      resizable: true,
    },
    {
      headerName: '',
      field: 'nummer',
      cellRenderer: 'DeleteButtonCell',
      width: 50,
      cellStyle: { justifyContent: 'end' },
      cellRendererParams: {
        context: {
          clicked(field: { column: string }) {
            editSettingsStore.commit.deleteLeistung({
              key: key,
              items: [field.column.toLowerCase()],
            });
          },
        },
      },
    },
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
  ];
}
